import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SignInComponent } from '../components/sign-in/sign-in.component';
import { PageNotFoundComponent } from '../components/page-not-found/page-not-found.component';
import { DashboardComponent } from '../components/dashboard/dashboard.component';
import { AccountsComponent } from '../components/accounts/accounts.component';
import { SettingsComponent } from '../components/settings/settings.component';
import { MainComponent } from '../components/main/main.component';
import { AuthGuardService as AuthGuard } from '../services/auth-guard.service';
import { RolesComponent } from '../components/roles/roles.component';
import { ForbiddenComponent } from '../components/forbidden/forbidden.component';
import { AccountDetailsComponent } from '../components/accounts/account-details/account-details.component';
import { CreateAccountComponent } from '../components/accounts/create-account/create-account.component';
import { EditAccountComponent } from '../components/accounts/edit-account/edit-account.component';
import { ChangePasswordComponent } from '../components/accounts/change-password/change-password.component';
import { CreateRoleComponent } from '../components/roles/create-role/create-role.component';
import { EditRoleComponent } from '../components/roles/edit-role/edit-role.component';
import { RoleDetailsComponent } from '../components/roles/role-details/role-details.component';
import { DeleteAccountComponent } from '../components/accounts/delete-account/delete-account.component';
import { DeleteRoleComponent } from '../components/roles/delete-role/delete-role.component';
import { AccountRolesComponent } from '../components/accounts/account-roles/account-roles.component';
import { ProductionCalendarComponent } from '../components/production-calendar/production-calendar.component';
import { SettingsByGroupNameComponent } from '../components/settings/settings-by-group-name/settings-by-group-name.component';
import { EditSettingComponent } from '../components/settings/edit-setting/edit-setting.component';
import { SettingDetailsComponent } from '../components/settings/setting-details/setting-details.component';
import { DeleteSettingComponent } from '../components/settings/delete-setting/delete-setting.component';
import { ProductionLocationsComponent } from '../components/production-locations/production-locations.component';
// eslint-disable-next-line max-len
import { EditProductionLocationComponent } from '../components/production-locations/edit-production-location/edit-production-location.component';
// eslint-disable-next-line max-len
import { ProductionLocationDetailsComponent } from '../components/production-locations/production-location-details/production-location-details.component';
// eslint-disable-next-line max-len
import { DeleteProductionLocationComponent } from '../components/production-locations/delete-production-location/delete-production-location.component';
// eslint-disable-next-line max-len
import { CreateProductionLocationComponent } from '../components/production-locations/create-production-location/create-production-location.component';
import { RouterCimComponent } from '../components/router-cim/router-cim.component';
import { ApartmentsComponent } from '../components/apartments/apartments.component';
import { ProductionLimitsComponent } from '../components/production-limits/production-limits.component';
import { CreateProductionLimitComponent } from '../components/production-limits/create-production-limit/create-production-limit.component';
import { EditProductionLimitComponent } from '../components/production-limits/edit-production-limit/edit-production-limit.component';
import { DeleteProductionLimitComponent } from '../components/production-limits/delete-production-limit/delete-production-limit.component';
// eslint-disable-next-line max-len
import { ProductionLimitDetailsComponent } from '../components/production-limits/production-limit-details/production-limit-details.component';
import { UserRole } from '../models/user-role';
import { ScheduleManagerComponent } from '../components/schedule-manager/schedule-manager.component';
import { ScheduleComponent } from '../components/schedule/schedule.component';
import { MiterSawComponent } from '../components/miter-saw/miter-saw.component';
import { GibenComponent } from '../components/giben/giben.component';
import { ScanLookupsComponent } from '../components/scan-lookups/scan-lookups.component';
import { PromoCodesComponent } from '../components/promo-codes/promo-codes.component';
import { CreatePromoCodeComponent } from '../components/promo-codes/create-promo-code/create-promo-code.component';
import { EditPromoCodeComponent } from '../components/promo-codes/edit-promo-code/edit-promo-code.component';
import { PromoCodeDetailsComponent } from '../components/promo-codes/promo-code-details/promo-code-details.component';
import { DeletePromoCodeComponent } from '../components/promo-codes/delete-promo-code/delete-promo-code.component';
import { AccountingComponent } from '../components/accounting/accounting.component';
import { CreateRebateGroupComponent } from '../components/accounting/create-rebate-group/create-rebate-group.component';
import { EditRebateGroupComponent } from '../components/accounting/edit-rebate-group/edit-rebate-group.component';
import { DeleteRebateGroupComponent } from '../components/accounting/delete-rebate-group/delete-rebate-group.component';
import { RebateGroupDetailsComponent } from '../components/accounting/rebate-group-details/rebate-group-details.component';
import { CommissionDashboardComponent } from '../components/commissions/commission-dashboard/commission-dashboard.component';
import { CommunitiesCommissionsComponent } from '../components/commissions/communities-commissions/communities-commissions.component';
import { SummaryComponent } from '../components/summary/summary.component';
import { ReasonCodesComponent } from '../components/reason-codes/reason-codes.component';
import { CreateReasonCodeComponent } from '../components/reason-codes/create-reason-code/create-reason-code.component';
import { EditReasonCodeComponent } from '../components/reason-codes/edit-reason-code/edit-reason-code.component';
import { CreateReasonCategoryComponent } from '../components/reason-codes/create-reason-category/create-reason-category.component';
import { EditReasonCategoryComponent } from '../components/reason-codes/edit-reason-category/edit-reason-category.component';
import { ListReasonCodesComponent } from '../components/reason-codes/list-reason-codes/list-reason-codes.component';
import { ListReasonCategoriesComponent } from '../components/reason-codes/list-reason-categories/list-reason-categories.component';
import { OrderNotificationComponent } from '../components/order-notification/order-notification/order-notification.component';
import { CreateOrderNotificationComponent } from '../components/order-notification/create-order-notification/create-order-notification.component';
import { manifest } from './routes-manifest';
import { WhatsNotComponent } from '../components/whats-not/whats-not.component';
import { AuditComponent } from '../components/audit/audit.component';
import { OvernightDeliveryComponent } from '../components/overnight-delivery/overnight-main/overnight-delivery.component';
import { CustomerLookupComponent } from '../components/customer-lookup/customer-lookup.component';
import { ProductionMoldingDetailsComponent } from '../components/settings/production-molding/production-molding-details/production-molding-details.component';
import { CreateProductionMoldingComponent } from '../components/settings/production-molding/create-production-molding/create-production-molding.component';
import { EditProductionMoldingComponent } from '../components/settings/production-molding/edit-production-molding/edit-production-molding.component';
import { DeleteProductionMoldingComponent } from '../components/settings/production-molding/delete-production-molding/delete-production-molding.component';
import { WorkCenterComponent } from '../components/work-center/work-center.component';
import { RoutersComponent } from '../components/routers/routers.component';
import { CreateRouterComponent } from '../components/routers/create-router/create-router.component';
import { EditRouterComponent } from '../components/routers/edit-router/edit-router.component';
import { DeleteRouterComponent } from '../components/routers/delete-router/delete-router.component';
import { RouterDetailsComponent } from '../components/routers/router-details/router-details.component';
import { RouterSetupDetailsComponent } from '../components/routers/router-setup-details/router-setup-details.component';
import { CreateRouterSetupComponent } from '../components/routers/create-router-setup/create-router-setup.component';
import { EditRouterSetupComponent } from '../components/routers/edit-router-setup/edit-router-setup.component';
import { DeleteRouterSetupComponent } from '../components/routers/delete-router-setup/delete-router-setup.component';
import { CreateRouterScheduleComponent } from '../components/routers/create-router-schedule/create-router-schedule.component';
import { EditRouterScheduleComponent } from '../components/routers/edit-router-schedule/edit-router-schedule.component';
import { DeleteRouterScheduleComponent } from '../components/routers/delete-router-schedule/delete-router-schedule.component';
import { RouterScheduleDetailsComponent } from '../components/routers/router-schedule-details/router-schedule-details.component';
import { ExportToProductionExpandedComponent } from '../components/schedule-manager/export-to-production-expanded/export-to-production-expanded.component';
import { CreateExpeditedProductionComponent } from '../components/settings/expedited-production/create-expedited-production/create-expedited-production.component';
import { EditExpeditedProductionComponent } from '../components/settings/expedited-production/edit-expedited-production/edit-expedited-production.component';
import { DeleteExpeditedProductionComponent } from '../components/settings/expedited-production/delete-expedited-production/delete-expedited-production.component';
import { DetailExpeditedProductionComponent } from '../components/settings/expedited-production/detail-expedited-production/detail-expedited-production.component';
import { PalletizationComponent } from '../components/palletization/palletization.component';
import { CreateReasonGroupComponent } from '../components/reason-codes/create-reason-group/create-reason-group.component';
import { EditReasonGroupComponent } from '../components/reason-codes/edit-reason-group/edit-reason-group.component';
import { CreateShipInSetupComponent } from '../components/settings/standard-item-shipping/create-shipping-setup/create-ship-in-setup.component';
import { DeleteShipInSetupComponent } from '../components/settings/standard-item-shipping/delete-shipping-setup/delete-ship-in-setup.component';
import { CreateStrategicExpeditedComponent } from '../components/settings/strategic-expedited/create-strategic-expedited/create-strategic-expedited.component';
import { EditStrategicExpeditedComponent } from '../components/settings/strategic-expedited/edit-strategic-expedited/edit-strategic-expedited.component';
import { DeleteStrategicExpeditedComponent } from '../components/settings/strategic-expedited/delete-strategic-expedited/delete-strategic-expedited.component';
import { DetailsStrategicExpeditedComponent } from '../components/settings/strategic-expedited/details-strategic-expedited/details-strategic-expedited.component';
import { WhatsNotScheduledComponent } from '../components/whats-not-scheduled/whats-not-scheduled.component';
import { FinalQaComponent } from '../components/final-qa/final-qa.component';
import { TableSawComponent } from '../components/table-saw/table-saw.component';
import { ScheduleLookAheadComponent } from '../components/schedule-look-ahead/schedule-look-ahead.component';
import { WhatNotLabelledComponent } from '../components/what-not-labelled/what-not-labelled.component';

const routes: Routes = [
  {
    path: '', component: MainComponent,
    children: [
      {
        path: 'dashboard', component: DashboardComponent,
        canActivate: [AuthGuard],
        data: {
          roles: [...manifest['dashboard'].roles]
        }
      },
      {
        path: 'calendar', component: ProductionCalendarComponent,
        canActivate: [AuthGuard],
        data: {
          roles: [...manifest['calendar'].roles]
        }
      },
      {
        path: 'schedule-manager',
        children: [
          { path: '', component: ScheduleManagerComponent },
          { path: 'export-to-production', component: ExportToProductionExpandedComponent },
        ],
        canActivate: [AuthGuard],
        data: {
          roles: [...manifest['schedule-manager'].roles]
        }
      },
      {
        path: 'schedule', component: ScheduleComponent,
        canActivate: [AuthGuard],
        data: {
          roles: [...manifest['schedule'].roles]
        }
      },
      {
        path: 'whats-not', component: WhatsNotComponent,
        canActivate: [AuthGuard],
        data: {
          roles: [...manifest['whats-not'].roles]
        }
      },
      {
        path: 'whats-not-scheduled', component: WhatsNotScheduledComponent,
        canActivate: [AuthGuard],
        data: {
          roles: [...manifest['whats-not-scheduled'].roles]
        }
      },
      {
        path: 'summary', component: SummaryComponent,
        canActivate: [AuthGuard],
        data: {
          roles: [...manifest['summary'].roles]
        }
      },
      {
        path: 'router-cim', component: RouterCimComponent,
        canActivate: [AuthGuard],
        data: {
          roles: [...manifest['router-cim'].roles]
        }
      },
      {
        path: 'giben', component: GibenComponent,
        canActivate: [AuthGuard],
        data: {
          roles: [...manifest['giben'].roles]
        }
      },
      {
        path: 'miter-saw', component: MiterSawComponent,
        canActivate: [AuthGuard],
        data: {
          roles: [...manifest['miter-saw'].roles]
        }
      },
      {
        path: 'apartments/:production-order-number', component: ApartmentsComponent,
        canActivate: [AuthGuard],
        data: {
          roles: [...manifest['apartments'].roles]
        }
      },
      {
        path: 'production-limits', children: [
          { path: '', component: ProductionLimitsComponent },
          { path: 'create', component: CreateProductionLimitComponent },
          { path: 'edit/:id', component: EditProductionLimitComponent },
          { path: 'details/:id', component: ProductionLimitDetailsComponent },
          { path: 'delete/:id', component: DeleteProductionLimitComponent },
        ],
        canActivate: [AuthGuard],
        data: {
          roles: [...manifest['production-limits'].roles]
        }
      },
      {
        path: 'settings', children: [
          { path: '', component: SettingsComponent },
          { path: 'edit/:id', component: EditSettingComponent },
          { path: 'details/:id', component: SettingDetailsComponent },
          { path: 'delete/:id', component: DeleteSettingComponent },
          { path: 'group-by/:name', component: SettingsByGroupNameComponent },
          { path: 'production-molding/create', component: CreateProductionMoldingComponent },
          { path: 'production-molding/edit/:id', component: EditProductionMoldingComponent },
          { path: 'production-molding/delete/:id', component: DeleteProductionMoldingComponent },
          { path: 'production-molding/details/:id', component: ProductionMoldingDetailsComponent },
          { path: 'expedited-production/create', component: CreateExpeditedProductionComponent },
          { path: 'expedited-production/edit/:id', component: EditExpeditedProductionComponent },
          { path: 'expedited-production/delete/:id', component: DeleteExpeditedProductionComponent },
          { path: 'expedited-production/details/:id', component: DetailExpeditedProductionComponent },
          { path: 'standard-item-shipping/create', component: CreateShipInSetupComponent },
          { path: 'standard-item-shipping/delete/:id', component: DeleteShipInSetupComponent },
          { path: 'create-strategic-expedited/create', component: CreateStrategicExpeditedComponent },
          { path: 'edit-strategic-expedited/edit/:id', component: EditStrategicExpeditedComponent },
          { path: 'delete-strategic-expedited/delete/:id', component: DeleteStrategicExpeditedComponent },
          { path: 'details-strategic-expedited/details/:id', component: DetailsStrategicExpeditedComponent }
        ],
        canActivate: [AuthGuard],
        data: {
          roles: [...manifest['settings'].roles]
        }
      },
      {
        path: 'promo-codes', children: [
          { path: '', component: PromoCodesComponent },
          { path: 'create', component: CreatePromoCodeComponent },
          { path: 'edit/:id', component: EditPromoCodeComponent },
          { path: 'details/:id', component: PromoCodeDetailsComponent },
          { path: 'delete/:id', component: DeletePromoCodeComponent },
        ],
        canActivate: [AuthGuard],
        data: {
          roles: [...manifest['promo-codes'].roles]
        }
      },
      {
        path: 'audit', component: AuditComponent,
        canActivate: [AuthGuard],
        data: {
          roles: [...manifest['audit'].roles]
        }
      },
      {
        path: 'customer-lookup', component: CustomerLookupComponent,
        canActivate: [AuthGuard],
        data: {
          roles: [...manifest['customer-lookup'].roles]
        }
      },
      {
        path: 'locations', children: [
          { path: '', component: ProductionLocationsComponent },
          { path: 'create', component: CreateProductionLocationComponent },
          { path: 'edit/:id', component: EditProductionLocationComponent },
          { path: 'details/:id', component: ProductionLocationDetailsComponent },
          { path: 'delete/:id', component: DeleteProductionLocationComponent },
        ],
        canActivate: [AuthGuard],
        data: {
          roles: [...manifest['locations'].roles]
        }
      },
      {
        path: 'accounts',
        children: [
          { path: '', component: AccountsComponent },
          { path: 'create', component: CreateAccountComponent },
          { path: 'delete/:id', component: DeleteAccountComponent },
          { path: 'roles/:id', component: AccountRolesComponent }
        ],
        canActivate: [AuthGuard],
        data: {
          roles: [...manifest['accounts'].roles]
        }
      },
      {
        path: 'account',
        children: [
          { path: 'edit/:id', component: EditAccountComponent },
          { path: 'details/:id', component: AccountDetailsComponent },
          { path: 'change-password/:id', component: ChangePasswordComponent },
        ]
      },
      {
        path: 'roles', children: [
          { path: '', component: RolesComponent },
          { path: 'create', component: CreateRoleComponent },
          { path: 'edit/:id', component: EditRoleComponent },
          { path: 'details/:id', component: RoleDetailsComponent },
          { path: 'delete/:id', component: DeleteRoleComponent },
        ],
        canActivate: [AuthGuard],
        data: {
          roles: [...manifest['roles'].roles]
        }
      },
      {
        path: 'accounting', children: [
          { path: '', component: AccountingComponent },
          { path: 'create', component: CreateRebateGroupComponent },
          { path: 'edit/:id', component: EditRebateGroupComponent },
          { path: 'details/:id', component: RebateGroupDetailsComponent },
          { path: 'delete/:id', component: DeleteRebateGroupComponent },
        ],
        canActivate: [AuthGuard],
        data: {
          roles: [...manifest['accounting'].roles]
        }
      },
      {
        path: 'scan-lookups', children: [
          {
            path: '', component: ScanLookupsComponent,
            canActivate: [AuthGuard],
            data: {
              roles: [...manifest['scan-lookups'].roles]
            }
          }
        ]
      },
      {
        path: 'commissions', children: [
          { path: '', component: CommissionDashboardComponent },
          { path: 'communities/:id', component: CommunitiesCommissionsComponent }
        ],
        canActivate: [AuthGuard],
        data: {
          roles: [...manifest['commissions'].roles]
        }
      },
      {
        path: 'order-notification', children: [
          { path: '', component: OrderNotificationComponent },
          { path: 'create', component: CreateOrderNotificationComponent }
        ],
        canActivate: [AuthGuard],
        data: {
          roles: [...manifest['order-notification'].roles]
        }
      },
      {
        path: 'overnight-delivery', component: OvernightDeliveryComponent,
        canActivate: [AuthGuard],
        data: {
          roles: [...manifest['overnight-delivery'].roles]
        }
      },
      {
        path: 'palletization', component: PalletizationComponent,
        canActivate: [AuthGuard],
        data: {
          roles: [...manifest['palletization'].roles]
        }
      },
      {
        path: 'reason-codes', children: [
          { path: '', component: ReasonCodesComponent },
          { path: 'reasons', component: ListReasonCodesComponent },
          { path: 'reason-create', component: CreateReasonCodeComponent },
          { path: 'reason-edit/:id', component: EditReasonCodeComponent },
          { path: 'categories', component: ListReasonCategoriesComponent },
          { path: 'category-create', component: CreateReasonCategoryComponent },
          { path: 'group-create', component: CreateReasonGroupComponent },
          { path: 'group-edit/:id', component: EditReasonGroupComponent },
          { path: 'category-edit/:id', component: EditReasonCategoryComponent }
        ],
        canActivate: [AuthGuard],
        data: {
          roles: [...manifest['reason-codes'].roles]
        }
      },
      {
        path: 'work-center', component: WorkCenterComponent,
        canActivate: [AuthGuard],
        data: {
          roles: [...manifest['work-center'].roles]
        }
      },
      {
        path: 'routers', children: [
          { path: '', component: RoutersComponent },
          { path: 'create', component: CreateRouterComponent },
          { path: 'edit/:id', component: EditRouterComponent },
          { path: 'details/:id', component: RouterDetailsComponent },
          { path: 'delete/:id', component: DeleteRouterComponent },
        ],
        canActivate: [AuthGuard],
        data: {
          roles: [...manifest['routers'].roles]
        }
      },
      {
        path: 'router-schedules', children: [
          { path: 'create', component: CreateRouterScheduleComponent },
          { path: 'edit/:id', component: EditRouterScheduleComponent },
          { path: 'details/:id', component: RouterScheduleDetailsComponent },
          { path: 'delete/:id', component: DeleteRouterScheduleComponent },
        ],
        canActivate: [AuthGuard],
        data: {
          roles: [...manifest['routers'].roles]
        }
      },
      {
        path: 'router-setups', children: [
          { path: 'create', component: CreateRouterSetupComponent },
          { path: 'edit/:id', component: EditRouterSetupComponent },
          { path: 'details/:id', component: RouterSetupDetailsComponent },
          { path: 'delete/:id', component: DeleteRouterSetupComponent },
        ],
        canActivate: [AuthGuard],
        data: {
          roles: [...manifest['routers'].roles]
        }
      },
      {
        path: 'final-qa', children: [
          { path: '', component: FinalQaComponent }
        ],
        canActivate: [AuthGuard],
        data: {
          roles: [...manifest['final-qa'].roles]
        }
      },
      {
        path: 'table-saw', children: [
          { path: '', component: TableSawComponent }
        ],
        canActivate: [AuthGuard],
        data: {
          roles: [...manifest['table-saw'].roles]
        }
      },
      {
        path: 'must-ship-tomorrow', children: [
          { path: '', component: ScheduleLookAheadComponent }
        ],
        canActivate: [AuthGuard],
        data: {
          roles: [...manifest['must-ship-tomorrow'].roles]
        }
      },
      {
        path: 'whats-not-labelled', children: [
          { path: '', component: WhatNotLabelledComponent }
        ],
        canActivate: [AuthGuard],
        data: {
          roles: [...manifest['whats-not-labelled'].roles]
        }
      },
      { path: 'forbidden', component: ForbiddenComponent },
    ],
    canActivate: [AuthGuard]
  },
  { path: 'sign-in', component: SignInComponent },
  { path: '**', component: PageNotFoundComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { enableTracing: false })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
