import { Component } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { StrategicExpedited } from '../../../../models/strategic-expedited';
import { SettingsService } from '../../../../services/settings.service';
import { SnackbarService } from '../../../../services/snackbar.service';

@Component({
  selector: 'app-create-strategic-expedited',
  templateUrl: './create-strategic-expedited.component.html',
  styleUrls: ['./create-strategic-expedited.component.less']
})
export class CreateStrategicExpeditedComponent {
  strategicExpeditedForm: FormGroup;
  strategicExpeditedSettingId: number;
  errorMessages: string[] = [];

  constructor(
    private router: Router,
    private fb: FormBuilder,
    private settingsService: SettingsService,
    private snackBarService: SnackbarService
  ) {
    this.strategicExpeditedForm = this.fb.group({
      transitDays: ['', [Validators.required, Validators.min(0)]],
      state: ['', [Validators.required, this.commaSeparatedUppercaseValidator]],
      isActive: [false]
    });
  }
  submit() {
    if (this.strategicExpeditedForm.valid) {
      const updatedSetting: StrategicExpedited = this.strategicExpeditedForm.value;
      this.settingsService.createStrategicExpedited(updatedSetting).subscribe(
        () => {
          this.router.navigate(["/settings"]);
          this.snackBarService.showSuccess('Strategic Expedited setting was created successfully.');
        },
        err => {
          console.error('Error creating Strategic Expedited setting', err);
          this.snackBarService.showError('Error creating Strategic Expedited setting.');
        }
      );
    } else {
      this.errorMessages = [];
      if (this.strategicExpeditedForm.get('state')?.hasError('invalidCommaSeparatedState')) {
        this.errorMessages.push('States should be uppercase letters separated by commas.');
      }
    }
  }

  commaSeparatedUppercaseValidator(control: AbstractControl): ValidationErrors | null {
    const value = control.value ? control.value.trim() : '';
    if (!value) return null;
    const isValid = /^[a-zA-Z]{2}(,[a-zA-Z]{2})*$/.test(value);
    return isValid ? null : { invalidCommaSeparatedState: true };
  }
}
