<mat-card class="mat-elevation-z2">
  <h1 class="mat-h1">Delete Strategic Expedited Setting</h1>
  <hr />
  <p>Are you sure you want to delete this setting?</p>
  <div class="content">
    <ul>
      <li><strong>Id:</strong> {{ strategicExpeditedSettings?.id }}</li>
      <li>
        <strong>Transit Days:</strong>
        {{ strategicExpeditedSettings?.transitDays }}
      </li>
      <li>
        <strong>States:</strong>
        {{ strategicExpeditedSettings?.state }}
      </li>
      <li>
        <strong>Is Active:</strong>
        {{ strategicExpeditedSettings?.isActive | active }}
      </li>
    </ul>
  </div>
  <div class="actions">
    <button mat-raised-button color="accent" (click)="deleteStrategicExpeditedSetting()">
      Delete
    </button>
  </div>
</mat-card>