import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { StrategicExpedited } from '../../../../models/strategic-expedited';
import { SettingsService } from '../../../../services/settings.service';
import { SnackbarService } from '../../../../services/snackbar.service';

@Component({
  selector: 'app-delete-strategic-expedited',
  templateUrl: './delete-strategic-expedited.component.html',
  styleUrls: ['./delete-strategic-expedited.component.less']
})
export class DeleteStrategicExpeditedComponent implements OnInit {

  strategicExpeditedSettings: StrategicExpedited;
  strategicExpeditedSettingId: number;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private settingsService: SettingsService,
    private snackBarService: SnackbarService
  ) { }

  ngOnInit() {
    this.route.params.subscribe((params) => {
      this.strategicExpeditedSettingId = +params['id'];
      this.getStrategicExpeditedSettingById(this.strategicExpeditedSettingId);
    });
  }

  getStrategicExpeditedSettingById(id: number) {
    this.settingsService.getStrategicExpeditedById(id).subscribe(
      (response: StrategicExpedited) => {
        this.strategicExpeditedSettings = response;
      },
      err => {
        console.error('Error getting Strategic Expedited setting', err);
        this.snackBarService.showError('Error getting Strategic Expedited setting.');
      }
    );
  }


  deleteStrategicExpeditedSetting() {
    this.settingsService
      .deleteStrategicExpedited(this.strategicExpeditedSettings.id)
      .subscribe(
        () => {
          this.router.navigate([`/settings`]);
          this.snackBarService.showSuccess(
            "Strategic Expedited setting was deleted successfully."
          );
        },
        (err) => {
          console.error(err);
          this.snackBarService.showError(
            "Error deleting Strategic Expedited setting."
          );
        }
      );
  }
}
