<mat-card class="mat-elevation-z2">
  <h1 class="mat-h1">Create Strategic Expedited Setting</h1>
  <hr />

  <div class="container">
    <ul class="errors" *ngIf="errorMessages.length > 0">
      <li *ngFor="let message of errorMessages">{{ message }}</li>
    </ul>
    <form [formGroup]="strategicExpeditedForm" (ngSubmit)="submit()">
      <mat-form-field class="full-width">
        <mat-label>Transit Days</mat-label>
        <input matInput type="number" placeholder="Transit Days" formControlName="transitDays" />
        <mat-error *ngIf="strategicExpeditedForm.get('transitDays').hasError('required')">
          Transit Days is required
        </mat-error>
        <mat-error *ngIf="strategicExpeditedForm.get('transitDays').hasError('min')">
          Transit Days cannot be negative
        </mat-error>
      </mat-form-field>

      <mat-form-field class="full-width">
        <mat-label>States</mat-label>
        <input matInput placeholder="States" formControlName="state" />
        <mat-error *ngIf="strategicExpeditedForm.get('state').hasError('required')">
          States is required
        </mat-error>
        <mat-error *ngIf="strategicExpeditedForm.get('state').hasError('invalidCommaSeparatedState')">
          States should be uppercase letters separated by commas. Example: "CA, NY, TX"
        </mat-error>
      </mat-form-field>

      <div class="checkbox-container">
        <mat-checkbox formControlName="isActive">Is Active</mat-checkbox>
      </div>

      <div class="actions">
        <button mat-raised-button color="accent" type="submit">
          Create
        </button>
      </div>
    </form>
  </div>
</mat-card>