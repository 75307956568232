import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators, AbstractControl, ValidationErrors } from '@angular/forms';
import { StrategicExpedited } from '../../../../models/strategic-expedited';
import { SettingsService } from '../../../../services/settings.service';
import { SnackbarService } from '../../../../services/snackbar.service';

@Component({
  selector: 'app-edit-strategic-expedited',
  templateUrl: './edit-strategic-expedited.component.html',
  styleUrls: ['./edit-strategic-expedited.component.less']
})
export class EditStrategicExpeditedComponent implements OnInit {
  strategicExpeditedForm: FormGroup;
  strategicExpeditedSettingId: number;
  errorMessages: string[] = [];

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private settingsService: SettingsService,
    private snackBarService: SnackbarService
  ) {
    this.strategicExpeditedForm = this.fb.group({
      transitDays: ['', [Validators.required, Validators.min(0)]],
      state: ['', [Validators.required, this.commaSeparatedUppercaseValidator]],
      isActive: [false]
    });
  }

  ngOnInit() {
    this.route.params.subscribe((params) => {
      this.strategicExpeditedSettingId = +params['id']; // Convert param to number
      this.getStrategicExpeditedById(this.strategicExpeditedSettingId);
    });
  }

  getStrategicExpeditedById(id: number) {
    this.settingsService.getStrategicExpeditedById(id).subscribe(
      (data: StrategicExpedited) => {
        this.strategicExpeditedForm.patchValue(data);
      },
      err => {
        console.error('Error getting Strategic Expedited setting', err);
        this.snackBarService.showError('Error getting Strategic Expedited setting.');
      }
    );
  }

  submit() {
    if (this.strategicExpeditedForm.valid) {
      const updatedSetting: StrategicExpedited = this.strategicExpeditedForm.value;
      this.settingsService.updateStrategicExpedited(this.strategicExpeditedSettingId, updatedSetting).subscribe(
        () => {
          this.router.navigate(["/settings"]);
          this.snackBarService.showSuccess('Strategic Expedited setting was updated successfully.');
        },
        err => {
          console.error('Error updating Strategic Expedited setting', err);
          this.snackBarService.showError('Error updating Strategic Expedited setting.');
        }
      );
    } else {
      this.errorMessages = [];
      if (this.strategicExpeditedForm.get('state')?.hasError('invalidCommaSeparatedState')) {
        this.errorMessages.push('States should be uppercase letters separated by commas.');
      }
    }
  }

  commaSeparatedUppercaseValidator(control: AbstractControl): ValidationErrors | null {
    const value = control.value ? control.value.trim() : '';
    if (!value) {
      return null;
    }
    const isValid = /^[a-zA-Z]{2}(,[a-zA-Z]{2})*$/.test(value);
    return isValid ? null : { invalidCommaSeparatedState: true };
  }
}
