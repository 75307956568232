import { UserRole } from '../models/user-role';

const allUserRoles = Object.keys(UserRole);

export const manifest = {
    dashboard: {
        title: 'Dashboard',
        path: '/dashboard',
        icon: 'dashboard',
        level: 1,
        active: true,
        roles: [...allUserRoles],
    },
    summary: {
        title: 'Summary',
        path: '/summary',
        icon: 'area_chart',
        level: 1,
        active: true,
        roles: [...allUserRoles],
    },
    calendar: {
        title: 'Calendar',
        path: '/calendar',
        icon: 'calendar_month',
        level: 1,
        active: true,
        roles: [UserRole.Admin, UserRole.ScheduleManager, UserRole.ProductionManager, UserRole.MaterialManager],
    },
    'whats-not': {
        title: 'What\'s Not',
        path: '/whats-not',
        icon: 'event_note',
        level: 1,
        active: true,
        roles: [UserRole.Admin, UserRole.ScheduleManager, UserRole.Scheduler, UserRole.ScheduleViewer, UserRole.FinalQA],
    },
    'whats-not-scheduled': {
        title: 'What\'s Not Scheduled',
        path: '/whats-not-scheduled',
        icon: 'event_busy',
        level: 1,
        active: true,
        roles: [UserRole.Admin, UserRole.ScheduleManager, UserRole.Scheduler, UserRole.ScheduleViewer],
    },
    schedule: {
        title: 'Schedule',
        path: '/schedule',
        icon: 'date_range',
        level: 1,
        active: true,
        roles: [UserRole.Admin, UserRole.ScheduleViewer, UserRole.Scheduler, UserRole.ScheduleManager, UserRole.MaterialManager, UserRole.FinalQA],
    },
    'schedule-manager': {
        title: 'Schedule Manager',
        path: '/schedule-manager',
        icon: 'today',
        level: 1,
        active: true,
        roles: [UserRole.Admin, UserRole.ScheduleManager],
    },
    settings: {
        title: 'Settings',
        path: '/settings',
        icon: 'settings',
        level: 1,
        active: true,
        roles: [UserRole.Admin, UserRole.ScheduleManager, UserRole.MaterialManager, UserRole.ProductionManager, UserRole.ProductManagement],
    },
    'production-limits': {
        title: 'Production Limits',
        path: '/production-limits',
        icon: 'assessment',
        level: 1,
        active: true,
        roles: [UserRole.Admin, UserRole.ScheduleManager],
    },
    'promo-codes': {
        title: 'Promo Codes',
        path: '/promo-codes',
        icon: 'local_offer',
        level: 1,
        active: true,
        roles: [UserRole.Admin, UserRole.PromoCodeManager],
    },
    'router-cim': {
        title: 'Router-cim',
        path: '/router-cim',
        icon: 'article',
        level: 1,
        active: false,
        roles: [UserRole.Admin, UserRole.ProductionManager, UserRole.RouterSawOperator],
    },
    giben: {
        title: 'Giben',
        path: '/giben',
        icon: 'article',
        level: 1,
        active: true,
        roles: [UserRole.Admin, UserRole.ProductionManager, UserRole.RouterSawOperator, UserRole.MaterialManager],
    },
    'miter-saw': {
        title: 'Miter-saw',
        path: '/miter-saw',
        icon: 'article',
        level: 1,
        active: false,
        roles: [UserRole.Admin, UserRole.ProductionManager, UserRole.RouterSawOperator],
    },
    apartments: {
        title: 'Apartments',
        path: '/apartments',
        level: 2,
        active: true,
        roles: [UserRole.Admin, UserRole.ProductionManager, UserRole.RouterSawOperator],
    },
    'scan-lookups': {
        title: 'Scan Lookups',
        path: '/scan-lookups',
        icon: 'fact_check',
        level: 1,
        active: true,
        roles: [...allUserRoles],
    },
    accounting: {
        title: 'Accounting',
        path: '/accounting',
        icon: 'calculate',
        level: 1,
        active: true,
        roles: [UserRole.Admin, UserRole.Rebate],
    },
    commissions: {
        title: 'Commissions',
        path: '/commissions',
        icon: 'payments',
        level: 1,
        active: true,
        roles: [UserRole.Admin, UserRole.CommissionManager, UserRole.CommissionViewer],
    },
    'reason-codes': {
        title: 'Reason Codes',
        path: '/reason-codes',
        icon: 'format_list_numbered',
        level: 1,
        active: true,
        roles: [UserRole.Admin, UserRole.CustomerService],
    },
    'order-notification': {
        title: 'Order Notification',
        path: '/order-notification',
        icon: '3p',
        level: 1,
        active: true,
        roles: [UserRole.Admin, UserRole.ScheduleManager],
    },
    'overnight-delivery': {
        title: 'Overnight Delivery',
        path: '/overnight-delivery',
        icon: 'public',
        level: 1,
        active: true,
        roles: [UserRole.Admin, UserRole.ScheduleManager],
    },
    'palletization': {
        title: 'Palletization',
        path: '/palletization',
        icon: 'layers',
        level: 1,
        active: true,
        roles: [UserRole.Admin, UserRole.ScheduleManager],
    },
    audit: {
        title: 'Audit',
        path: '/audit',
        icon: 'checklist',
        level: 1,
        active: true,
        roles: [UserRole.Admin, UserRole.ScheduleManager],
    },
    'customer-lookup': {
        title: 'Customer Lookup',
        path: '/customer-lookup',
        icon: 'find_in_page',
        level: 1,
        active: true,
        roles: [UserRole.Admin, UserRole.ScheduleManager],
    },
    locations: {
        title: 'Locations',
        path: '/locations',
        icon: 'map',
        level: 1,
        active: true,
        roles: [UserRole.Admin, UserRole.ScheduleManager, UserRole.ProductionManager],
    },
    accounts: {
        title: 'Accounts',
        path: '/accounts',
        icon: 'people',
        level: 1,
        active: true,
        roles: [UserRole.Admin],
    },
    roles: {
        title: 'Roles',
        path: '/roles',
        icon: 'assignment_ind',
        level: 1,
        active: true,
        roles: [UserRole.Admin],
    },
    'work-center': {
        title: 'Work Center',
        path: '/work-center',
        icon: 'home_repair_service',
        level: 1,
        active: true,
        roles: [UserRole.Admin],
    },
    'routers': {
        title: 'Routers',
        path: '/routers',
        icon: 'integration_instructions',
        level: 1,
        active: true,
        roles: [UserRole.Admin, UserRole.ProductionManager, UserRole.ScheduleManager],
    },
    'final-qa': {
        title: 'Final QA',
        path: '/final-qa',
        icon: 'info',
        level: 1,
        active: true,
        roles: [UserRole.Admin, UserRole.ProductionManager, UserRole.ScheduleManager, UserRole.FinalQA],
    },
    'table-saw': {
        title: 'Table Saw',
        path: '/table-saw',
        icon: 'table_restaurant',
        level: 1,
        active: true,
        roles: [UserRole.Admin, UserRole.ProductionManager, UserRole.RouterSawOperator],
    },
    'must-ship-tomorrow': {
        title: 'Must Ship Tomorrow',
        path: '/must-ship-tomorrow',
        icon: 'event_available',
        level: 1,
        active: true,
        roles: [UserRole.Admin, UserRole.ProductionManager, UserRole.ScheduleManager],
    },
    'whats-not-labelled': {
        title: 'What\'s Not in the Truck',
        path: '/whats-not-labelled',
        icon: 'local_shipping',
        level: 1,
        active: true,
        roles: [UserRole.Admin, UserRole.ProductionManager, UserRole.FinalQA],
    },
};